.nav-link {
  /* color: #ffffff !important; */
  font-size: 14px !important;
}

.navbar-responsive {
  display: flex;
  align-items: center !important;
}

.nav-link.active {
  color: #C4262E !important;
}

.active-menu {
  color: #C4262E !important;
}

.account-link {
  background-color: #C4262E !important;
  border-radius: 30px !important;
  padding: 4px 12px !important;
}

.dropdown-menu {
  width: 100% !important;
}

.dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: auto !important;
}

.rounded-circle {
  border: 1px solid #9F9F9F;
  padding: 0px !important;
}

.rounded-circle::after {
  display: none !important;
}

.profile-dropdown {
  width: 200px !important;
}
.dropdown-menu.show {
  width: 200px !important;
}
.dropdown-item {
  width: 200px !important;
  white-space: normal !important;
  word-wrap: break-word;
}

.dividers.dropdown-item{
  border-bottom: 1px solid #cecccc;
}

.profile-divider {
  border-top: 1px solid #637381 !important;
}

.dropdown-item:hover {
  background-color: #e6effe !important;
  /* border-radius: 6px !important; */
}

.header-logo{
  width: 180px;
}

/* footer */
.navbar {
  --bs-navbar-padding-y: 0px !important;
}

@media only screen and (max-width: 600px) {
  .navbar-responsive {
    display: flex;
    align-items: flex-start !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
    border: 1px solid #C4262E !important;
  }
  .navbar-collapse {
    padding-left: 5px;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar-responsive {
    display: flex;
    align-items: flex-start !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
    border: 1px solid #C4262E !important;
  }

  .navbar-collapse {
    z-index: 2 !important;
    background-color: #ffffff !important;
    padding-left: 8px;
  }
}

.loader-container {
  display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    bottom: 0;
    right: 0;
    flex-direction: column;
    gap: 0px;
}

@keyframes flip {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(-1);
  }

  100% {
    transform: scaleX(1);
  }
}

.flipping-image {
  width: 80px;
  height: 80px;
  animation: flip 1s ease-in-out infinite;
}

.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.page-not-found-container h3 {
  margin-bottom: 10px;
}

.sticky-header{
  left: 0 !important;
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 1030 !important;
}
.fixed-bottoms{
  left: 0 !important;
  position: fixed !important;
  right: 0 !important;
  z-index: 4;
  bottom: 0 !important;
}