body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif !important;
  height: 100%;
  background-color: #394A58;
  -webkit-text-size-adjust: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mB-4 {
  margin-bottom: 4px !important;
}

.mB-5 {
  margin-bottom: 5px !important;
}
.mB-28 {
  margin-bottom: 28px !important;
}

.mT-3 {
  margin-top: 3px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.w-85 {
  width: 85% !important;
}

.w-64 {
  width: 64px !important;
}

.w-92 {
  width: 92px !important;
}

.w-94 {
  width: 94px !important;
}

.w-124 {
  width: 124px !important;
}

.w-130 {
  width: 130px !important;
}

.w-138 {
  width: 138px !important;
}

.w-110 {
  width: 110px !important;
}

.w-120 {
  width: 120px !important;
}

.w-188 {
  width: 188px !important;
}

.w-45 {
  width: 45px !important;
}

.w-24 {
  width: 24px !important;
}

.mL-auto {
  margin: 0 0 0 auto !important;
}

.h-100v {
  height: 100vh !important;
}

.bR-50 {
  border-radius: 50% !important;
}

.bR-30 {
  border-radius: 30px !important;
}

.bR-10 {
  border-radius: 10px !important;
}

.bR-6 {
  border-radius: 6px !important;
}

.flex-0 {
  flex: 0 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.w-165 {
  width: 165px !important;
}

.w-316 {
  width: 316px !important;
}

.w-350 {
  width: 450px !important;
}

.w-200 {
  width: 200px !important;
}

.h-220 {
  height: 220px !important;
}

.h-240 {
  height: 240px !important;
}

.h-280 {
  height: 280px !important;
}

.w-250 {
  width: 250px !important;
}

.w-165 {
  width: 165px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-900 {
  width: 900px !important;
}

.h-60 {
  height: 60px !important;
}

.h-460 {
  height: 460px !important;
}

.h-440 {
  height: 440px;
}

.h-110 {
  height: 110px !important;
}

.h-106 {
  height: 106px !important;
}

.R40 {
  right: 40px;
}

.R80 {
  right: 80px;
}

.text-color-blue {
  color: #1152A2;
}

.bg-blue {
  background-color: #1152A2 !important;
}

.bg-red {
  background-color: #F54D61 !important;
}

.border-red {
  border: 1px solid #F54D61 !important;
}

.bg-lightblue {
  background-color: #DFEDFF !important;
}

.bg-green {
  background-color: #2FC861 !important;
}

.bg-toast-success {
  background-color: #00ADA1 !important;
}

.footer-color {
  background-color: #C4262E !important;
}

.padding-4 {
  padding: 4px !important;
}

.pV-9 {
  padding: 0 9px !important;
}

.pad-0 {
  padding: 0 !important;
}

.mR-5 {
  margin-right: 5px !important;
}

.mL-5 {
  margin-left: 5px !important;
}

.h-100v {
  height: 100vh;
}

.text-grey {
  color: #394A58 !important;
}

.text-skyblue {
  color: #2684FF !important;
}

.text-red {
  color: #C4262E !important;
}

.text-green {
  color: #00ADA1 !important;
}

.pT-32 {
  padding-top: 32px !important;
}

.mR-40 {
  margin-right: 40px !important;
}

.bg-lightred {
  background-color: #f9d0d6 !important;
}

.w-40 {
  width: 40px !important;
}

.h-40 {
  height: 40px !important;
}

.h-42 {
  height: 42px !important;
}

.p-10 {
  padding: 10px !important;
}

.fs-13half {
  font-size: 13.5px !important;
}

.mR-90 {
  margin-right: 90px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.pR-20 {
  padding-right: 20px !important;
}

.zI-1 {
  z-index: 1;
}

.zI-2 {
  z-index: 2;
}

.pL-0 {
  padding-left: 0 !important;
}

.h-396 {
  height: 396px !important;
}

.dashboard-tabs {
  border: none !important;
  /* background-color: #212121 !important; */
}

.dashboard-tabs li .nav-link {
  padding: 10px 22px !important;
  color: #ffffff;
  font-size: 14px !important;
  border: none;
  border-bottom: 2px solid darkgrey;
  border-radius: 0px !important;
  /* background-color: #212121; */
  white-space: nowrap;
}

.dashboard-tabs li #uncontrolled-tab-example-tab-1 {
  padding: 10px 34px 10px 4px !important;
  /* margin: 0 30px 0 0; */
  color: #FFFFFF;
  font-size: 14px !important;
  border-bottom: 2px solid darkgrey;
  border-radius: 0px !important
}

.dashboard-tabs li #uncontrolled-tab-example-tab-1.active {
  color: #C4262E;
  font-size: 14px !important;
  font-weight: 600;
  border-bottom: 2px solid #C4262E;
  border-radius: 0px !important
}

.dashboard-tabs li #uncontrolled-tab-example-tab-6 {
  padding: 10px 34px 10px 4px !important;
  /* margin: 0 0 0 30px; */
  color: #FFFFFF;
  font-size: 14px !important;
  border-radius: 0px !important
}

.dashboard-tabs li #uncontrolled-tab-example-tab-6.active {
  color: #C4262E;
  font-size: 14px !important;
  font-weight: 600;
  border-bottom: 2px solid #C4262E;
  border-radius: 0px !important
}

.dashboard-tabs li .nav-link.active {
  color: #C4262E;
  background-color: #FFFFFF !important;
  font-size: 14px !important;
  font-weight: 600;
  border-bottom: 2px solid #C4262E;
  border-radius: 0px !important;
  white-space: nowrap;
}

.dashboard-tabs li .nav-link span {
  display: flex !important;
  align-items: center;
}

.tab-content {
  /* background-color: #ffffff; */
}

.border-divider {
  position: relative;
  /* left: 90px; */
  left: 100px;
  bottom: 9px;
  width: 100%;
}

.border-divider2 {
  position: relative;
  left: 125px;
  bottom: 9px;
  width: 980px;
}

.border-divider3 {
  position: relative;
  left: 196px;
  bottom: 9px;
  width: 908px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

tbody,
tr {
  height: 0;
}

.btn-primary {
  --bs-btn-color: #637381 !important;
  --bs-btn-bg: #ffffff !important;
  --bs-btn-border-color: #637381 !important;
  --bs-btn-hover-border-color: #637381 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ffffff !important;
  --bs-btn-disabled-border-color: #637381 !important;
  --bs-btn-hover-color: #637381 !important;
  --bs-btn-hover-bg: #ffffff !important;
  --bs-btn-active-color: #637381 !important;
  --bs-btn-active-bg: #ffffff !important;
  --bs-btn-active-border-color: #637381 !important;
}

.view-btn {
  width: 66px;
  padding: 4px 8px;
  border: 1px solid #637381;
  cursor: pointer !important;
}

.view-statement-btn {
  width: 128px;
  padding: 4px 8px;
  border: 1px solid #637381;
}

.pR-20 {
  padding-right: 20px !important;
}

.line-wrap {
  position: absolute !important;
  top: 196px !important;
}

.line-style {
  width: 66px;
  transform: rotate(25deg);
}

.table-responsive {
  height: 322px !important;
}

.mob-toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookies-container {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 1111 !important;
}

.width-380 {
  width: 380px;
}

.calc-inner-wrap .btn {
  padding: 4px 4px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-btn {
  width: 164px !important;
}

/* mobile */
@media only screen and (max-width: 600px) {

  .download-btn {
    width: 174px !important;
  }

  .header-logo {
    width: 180px;
  }

  .w-140 {
    width: 140px;
  }

  .dashboard-tabs li #uncontrolled-tab-example-tab-/request/provisional-interest-calculation {
    padding: 10px 22px !important;
  }

  .dashboard-tabs li .nav-link {
    padding: 10px 22px !important;
  }

  .dashboard-tabs li .nav-link {
    background-color: #212121;
    border: none;
    padding: 10px 22px !important;
  }

  .view-btn {
    width: 100% !important;
  }

  .view-statement-btn {
    width: 100% !important;
  }

  .width-380 {
    width: 100% !important;
  }

  .w-316 {
    width: 212px !important;
  }

  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  .mob-nav {
    width: 100% !important;
  }

  .line-style {
    display: none;
  }

  .mob-container {
    width: 100% !important;
    overflow: auto;
  }

  .mob-wrap-height {
    height: 100% !important;
  }

  .tab-icon-1 {
    font-size: 38px !important;
  }

  .tab-icon-2 {
    font-size: 40px !important;
  }

  .dl-icon {
    font-size: 22px !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    vertical-align: top !important;
    /* text-align: center !important; */
  }

  .table-responsive {
    height: calc(100vh - 350px) !important;
  }

  .table {
    height: auto !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .table-head-position {
    top: -1px;
  }

  /* mobile header */
  .mobile-profile {
    position: absolute;
    display: block;
    top: 9px;
    cursor: pointer;
    right: 75px;
  }

  .dropdown-menu[data-bs-popper] {
    right: -80px !important;
    left: auto !important;
  }
  .dropdown-menu.show{
    width: 200px !important;
  }

  .profile-dropdown {
    width: 200px !important;
  }

  .h-396 {
    height: calc(100vh - 188px) !important;
  }

  .mob-toast {
    display: block !important;
  }

  .border-divider3 {
    position: relative;
    left: 196px;
    bottom: 9px;
    width: 138px;
  }

  .h-240 {
    height: 280px !important;
  }
}

.list-heading{
  display: flex;
  justify-content: space-between;
}

/* mobile SE Portrait */
@media only screen and (min-width: 375px) and (max-width: 600px) and (orientation: portrait) {
  .header-logo {
    width: 276px;
  }
}

/* mobile end */
/* mobile landscape */
/* mobile SE landscape */
@media only screen and (min-width: 360px) and (max-width: 740px) and (orientation: landscape) {
  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 932px) and (orientation: landscape) {
  .h-440 {
    height: 290px !important;
  }

  .w-450 {
    width: 325px !important;
  }

  .w-900 {
    width: 640px !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .table-head-position {
    top: -1px;
  }
}

@media only screen and (min-width: 601px) {
  .mobile-profile {
    display: none;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .mob-items-start {
    align-items: flex-start !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
    border: 1px solid #C4262E !important;
  }

  .navbar-collapse {
    z-index: 2 !important;
    background-color: #ffffff !important;
  }

  .table-responsive {
    height: 768px !important;
    height: calc(100vh - 350px) !important;
  }

  .h-240 {
    height: 280px !important;
  }
}

/* tab landscape */
@media only screen and (min-device-width: 788px) and (max-device-width: 1180px) and (orientation: landscape) {
  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  .table-responsive {
    height: 498px !important;
  }
}

/* ipad 9th gen */
@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) and (orientation: landscape) {
  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  .dashboard-tabs li .nav-link {
    padding: 10px 20px !important;
  }
}

/* ipad pro 9.7inch */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .dashboard-tabs {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow-x: auto !important;
  }

  .dashboard-tabs li .nav-link {
    padding: 10px 20px !important;
  }
}

/* mobile landscape */
@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .navbar-responsive {
    display: flex;
    align-items: flex-start !important;
  }

  .line-style {
    display: none !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .table-head-position {
    top: -1px;
  }
}

/* mobile landscape */
@media only screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape) {
  .navbar-responsive {
    display: flex;
    align-items: flex-start !important;
  }

  .line-style {
    display: none !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .table-head-position {
    top: -1px;
  }
}

/* mobile landscape */
@media only screen and (min-width: 430px) and (max-width: 932px) and (orientation: landscape) {
  .navbar-responsive {
    display: flex;
    align-items: flex-start !important;
  }

  .line-style {
    display: none !important;
  }

  .table>:not(caption)>*>* {
    white-space: nowrap !important;
  }

  .table-head-position {
    top: -1px;
  }
}

#detailModal .modal-header .btn-close {
  font-size: 14px !important;
}

#detailModal .modal-footer {
  justify-content: center !important;
}

.search-container button {
  float: right;
  padding: 0px 12px;
  background: #C4262E;
  font-size: 22px !important;
  border: none;
  cursor: pointer;
  border: 1px solid #C4262E !important;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0 !important;
}
.search-container button:hover{
  background-color: #C4262E;
}
.search-container button:active{
  background-color: #C4262E !important;
}
.search-box {
  height: 40px;
  display: flex;
}

.search-input {
  /* border: 1px solid rgb(231 24 55 / 30%) !important; */
  border-radius: 30px;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0 8px !important;
}
.search-input:focus-visible{
  outline: none;
}

.is-invalid {
    border-radius: 4px;
    border: 1px solid #C4262E !important;
  }

/* custome scrollbar */

::-webkit-scrollbar-track {
  background-color: #bdbdbd;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #eaeaea;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 10px;
}
